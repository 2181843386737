import instance from "../Instance";
import {
  ADD_EVENT,
  APPROVE_USER_DETAILS,
  BLOCK_USERS,
  DELETE_EVENT,
  DELETE_USERS,
  GET_EVENT,
  GET_USERS,

} from "./UserApiEndPoints";
import AWS from "aws-sdk";

export function getAllUsers(page, limit, search) {
  return instance.get(
    GET_USERS + `?page=${page}&limit=${limit}&search=${search}`
  );
}
export function blockUser(id) {
  const postData = {
    userId: id,
  };
  return instance.put(BLOCK_USERS, postData);
}
///////////=================events=========///////////////



export function getEvents(page, limit, search) {
  return instance.get(
    GET_EVENT + `?page=${page}&limit=${limit}&search=${search}`
  );
}

export function createEvent(formData) {
  return instance.post(
    ADD_EVENT ,formData
  );
}
export function deleteEvent(id) {

  return instance.delete(DELETE_EVENT+ `?_id=${id}`);
}


///////////////=========== image upload===================/////////////////////


export default async function addPhoto(image, albumName) {
  console.log(image, albumName, "file in test files");
  var file = image;
  var fileName = Math.floor(Math.random() * 10000000) + file.name;
  var albumPhotosKey = encodeURIComponent(albumName) + "/";
  var photoKey = albumPhotosKey + fileName;
console.log(process.env,"env data")
  const NEXT_PUBLIC_COGNITO_POOL_ID = process.env.REACT_APP_PUBLIC_COGNITO_POOL_ID;
  const NEXT_PUBLIC_COGNITO_POOL_REGION = process.env.REACT_APP_PUBLIC_COGNITO_POOL_REGION;
  const NEXT_PUBLIC_S3_BUCKET_NAME = process.env.REACT_APP_PUBLIC_S3_BUCKET_NAME;

  AWS.config.update({
    region: NEXT_PUBLIC_COGNITO_POOL_REGION,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: NEXT_PUBLIC_COGNITO_POOL_ID,
    }),
  });

  var upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: NEXT_PUBLIC_S3_BUCKET_NAME,
      Key: photoKey,
      Body: file,
      ACL: "public-read",
    },
  });

  var promise = await upload.promise();
  promise.imageName = fileName;
  // console.log(promise.imageName, "promise 1111111111111111");
  return promise;
}