import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";

import { MultiSelect } from "./MultiSelect";

import Spinner from "../common/Spinner";

import { getAllUsers } from "../../services/User/UserService";
import { pushNotification } from "../../services/AuthService";
import { notifyError, notifyTopRight } from "../common/Toaster";
export default function Notification(props) {
  const [loader, setLoader] = useState(false);

  // const [type, setType] = useState("all");
  let errorsObj = { title: "", body: "", selected: [] };
  const [errors, setErrors] = useState(errorsObj);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [selected, setSelected] = useState([]);
  const [userList, setUserList] = useState([]);

  const options = [
    ...userList?.map((item, i) => {
      return { value: item?._id, label: item?.name + "/" + item?.email };
    }),
  ];

  const totalIds = [...selected];
  // console.log(totalIds, "all ids")
  const ids = totalIds?.map((item, i) => {
    console.log(item?.value, "id...............");
    return item?.value;
  });

  async function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (title === "") {
      errorObj.title = "Title is Required";
      error = true;
    }
    if (body === "") {
      errorObj.body = "Body is Required";
      error = true;
    }
    if (selected.length === 0) {
      errorObj.selected = "Please select a user";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    setLoader(true);

    try {
      await pushNotification(title, body, ids);
      notifyTopRight("Sent Successfully.");
      setTitle("");
      setBody("");
      setSelected([]);
    } catch (error) {
      notifyError(error?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    setLoader(true);
    getAllUsers(0, 0, "")
      .then((response) => {
        console.log(response, "user data response");
        setUserList(response.data.data.users);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, []);
  return (
    <div>
      <PageTitle activeMenu="Notifications" motherMenu="Notifications" />

      <div className="col-lg-8 col-md-7 ">
        <div className="authincation-content text-black p-5">
          <div className="mb-4">
            <h3 className="mb-1 font-w600 text-black ">Add Details</h3>
          </div>
          {loader ? (
            <Spinner />
          ) : (
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <label className="mb-2 ">
                  <strong className="">Title</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter title"
                />
                {errors.title && (
                  <div className="text-danger fs-12">{errors.title}</div>
                )}
              </div>
              <div className="form-group">
                <label className="mb-2 ">
                  <strong className="">Body</strong>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  placeholder="Write something here.."
                />
                {errors.body && (
                  <div className="text-danger fs-12">{errors.body}</div>
                )}
              </div>
              <div className="form-group">
                <label className="mb-2 ">
                  <strong className="">Send To</strong>
                </label>

                <MultiSelect
                  className="form-control"
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  required
                />
                {errors.selected && (
                  <div className="text-danger fs-12">{errors.selected}</div>
                )}
              </div>

              <div className="text-center">
                <button type="submit" className="btn btn-primary btn-block">
                  Send
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
