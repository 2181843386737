// import axios from 'axios';
// const instance = axios.create({
//     baseURL: `https://api.tixscape.com/`,
// });
// export default instance;
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://api.tixscape.com/",
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("tixscapeDetails");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("tixscapeDetails");
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
