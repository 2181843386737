import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import { changePasswoard } from "../../services/AuthService";
import { notifyError, notifyTopRight } from "../common/Toaster";
export default function ChangePassword({ show, onHide }) {
  let errorsObj = { oldPassword: "", newPassword: "", confirmPassword: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [apiError, setApiError] = useState();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  async function onChangePassword(e) {
    console.log("ffffffff");
    e.preventDefault();
    let error = false;
    const errorObj = { ...errors };

    if (oldPassword.trim() === "") {
      errorObj.oldPassword = "Old Password is Required";
      error = true;
    }
    if (newPassword.trim() === "") {
      errorObj.newPassword = "New Password is Required";
      error = true;
    }
    if (confirmPassword.trim() === "") {
      errorObj.confirmPassword = "Confirm Password is Required";
      error = true;
    }
    if (oldPassword === newPassword && oldPassword !== "") {
      errorObj.newPassword = "Please create a new password";
      error = true;
    }
    if (newPassword !== confirmPassword) {
      errorObj.confirmPassword = "Password does not match";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    try {
      const response = await changePasswoard(oldPassword, newPassword);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      onHide();
      notifyTopRight("Updated Successfully.");
    } catch (error) {
      console.log(error.response, "change password error");
      notifyError(error.response?.data?.responseMessage);
    }
  }

  const handleOldPWDChange = (e) => {
    setOldPassword(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, oldPassword: "" }));
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, newPassword: "" }));
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: "" }));
  };

  const handleCancel = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setErrors({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    onHide();
  };
  return (
    <Modal className="modal fade" show={show} centered>
      <div className="" role="document">
        <div className="">
          <form onSubmit={onChangePassword}>
            <div className="modal-header">
              <h4 className="modal-title fs-20">Change Password</h4>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Old Password</label>
                  <div className="contact-name">
                    <input
                      type="password"
                      className="form-control"
                      autocomplete="off"
                      name="name"
                      value={oldPassword}
                      onChange={(e) => handleOldPWDChange(e)}
                      placeholder="Enter your old password"
                    />
                    <span className="validation-text"></span>
                    {errors.oldPassword && (
                      <div className="text-danger fs-12">
                        {errors.oldPassword}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">New Password</label>
                  <div className="contact-name">
                    <input
                      type="password"
                      className="form-control"
                      autocomplete="off"
                      name="department"
                      value={newPassword}
                      onChange={(e) => handlePasswordChange(e)}
                      placeholder="Enter new password"
                    />
                    <span className="validation-text"></span>
                    {errors.newPassword && (
                      <div className="text-danger fs-12">
                        {errors.newPassword}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">
                    Confirm New Password
                  </label>
                  <div className="contact-name">
                    <input
                      type="password"
                      className="form-control"
                      autocomplete="off"
                      name="department"
                      value={confirmPassword}
                      onChange={(e) => handleConfirmPasswordChange(e)}
                      placeholder="Re-enter new password"
                    />
                    <span className="validation-text"></span>
                    {errors.confirmPassword && (
                      <div className="text-danger fs-12">
                        {errors.confirmPassword}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-primary"
                // onClick={onChangePassword}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => handleCancel()}
                className="btn btn-secondary"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}
