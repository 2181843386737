import React, { useState } from "react";

import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";
import addPhoto, { createEvent } from "../../services/User/UserService";

export default function AddEvent({ show, table, onHide }) {
  let errorsObj = { image: "", name: "",type:"" };
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    image: "",
    name: "",
    type:""
  });

  const [loader, setLoader] = useState(false);
  let albumName = "tixscape";

  const handleInputChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      addPhoto(selectedFile, albumName)
        .then((response) => {
          console.log(response.Location, "image loaction");
          setFormData({ ...formData, image: response.Location });
          setErrors({ ...errors, image: "" });
        })
        .catch((error) => {
          console.log(error, "image upload error");
        });
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  //// function to post data ///
  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (formData?.image === "") {
      errorObj.image = "This field is required";
      error = true;
    }
    if (formData?.name.trim() === "") {
      errorObj.name = "This field is required";
      error = true;
    }
    if (formData?.type === "") {
      errorObj.type = "This field is required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    try {
      const response = await createEvent(formData);
      notifyTopRight(response?.data?.message);
      setFormData({ image: "", name: "" ,type:""});
      table();
      onHide();
      setLoader(false);
    } catch (error) {
      setLoader(false);
      notifyError(error.response?.data?.message);
    }
  };
  return (
    <Modal show={show} onHide={onHide} centered>
      <form onSubmit={onSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Add Details</h4>
        </div>
        <div className="modal-body">
          <div className="form-group mb-3">
            <label className="text-black font-w500">Image</label>
            <input
              type="file"
              accept="image/*"
              className="form-control"
              name="image"
              onChange={handleInputChange}
            />
            {errors.image && (
              <div className="text-danger fs-12">{errors.image}</div>
            )}
          </div>
          <div className="form-group mb-3">
            <label className="text-black font-w500">Name</label>
            <input
              className="form-control"
              name="name"
              value={formData.name}
              onChange={handleInput}
            />
            {errors.name && (
              <div className="text-danger fs-12">{errors.name}</div>
            )}
          </div>
          <div className="form-group mb-3 ">
            <label className="text-black font-w500">Category</label>
            <div className="contact-name">
              <select
                className="form-control"
                value={formData.type}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    type: e.target.value,
                  });
                  setErrors({ ...errors, type: "" });
                }}
              >
                <option hidden>Select</option>
                <option value={"sports"}>Sports</option>
                <option value={"concert"}>Concert</option>
                <option value={"theater"}>Theater</option>
                <option value={"comedy"}>Comedy</option>
                <option value={"other"}>Other</option>
              </select>

              <span className="validation-text"></span>
              {errors.type && (
                <div className="text-danger fs-12">{errors.type}</div>
              )}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
          <button type="button" onClick={onHide} className="btn btn-secondary">
            <i className="flaticon-delete-1"></i> Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
}
