import React, { useEffect, useState } from "react";
import { Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import {
  blockUser,
  deleteEvent,
  getAllUsers,
  getEvents,
} from "../../services/User/UserService";
import moment from "moment";
import dummyImg from "../../images/user-icon3.png";
import svgBlock from "../../images/svg/Block.svg";
import svgUnblock from "../../images/svg/Unblock.svg";
import { notifyError, notifyTopRight } from "../common/Toaster";
import AddEvent from "../modal/AddEvent";
export default function PopularEvents(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const limit = 10;
  const [listLength, setListLength] = useState("");
  const [initialFetch, setInitialFetch] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getEvents(currentPage, limit, search);
      console.log(response.data.data);
      setUsers(response.data.data?.data);
      setLoader(false);
      const total = response.data.data.count;
      setPageCount(Math.ceil(total / limit));
      setListLength(total);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };
  async function onAction(id) {
    setLoader(true);
    try {
      const response = await deleteEvent(id);
      notifyTopRight(response.data?.message);
      await getTableData();
      setLoader(false);
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      setLoader(false);
      notifyError(error.response?.data?.data);
    }
  }

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  let timeoutId;
  // Define a helper function for debouncing
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  const debouncedHandleFetch = debounce(getTableData, 500);
  useEffect(() => {
    if (initialFetch === false) {
      setCurrentPage(0);
      debouncedHandleFetch();
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [search]);
  return (
    <div>
      <PageTitle activeMenu="Event Cards" motherMenu="Event Management" />
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <div className="col-8" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      style={{
                        paddingBottom: "25px",
                        paddingTop: "25px",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={getTableData}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary px-3 py-2 fs-14"
                onClick={() => setShowModal(true)}
              >
                Add +
              </button>
            </div>
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>Profile</strong>
                    </th>
                    <th>
                      <strong>Name</strong>
                    </th>
                    <th>
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <img
                          src={item?.image ? item?.image : dummyImg}
                          width={80}
                          height={80}
                          alt="img"
                        />
                      </td>
                      <td>{item?.name ? item?.name : "--"}</td>

                      <td>
                        <button
                        type="button"
                         className="btn btn-danger"
                          style={{ cursor: "pointer" }}
                          onClick={() => onAction(item?._id)}
                        >
                          <i className="flaticon-381-trash"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {users?.length === 0 ? (
              ""
            ) : (
              <div className="card-footer clearfix">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info ">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      {showModal && (
        <AddEvent show={showModal} table={getTableData} onHide={() => setShowModal(false)} />
      )}
    </div>
  );
}
